import {useMemo, useState} from "react";
import { PlusIcon, ArrowsUpDownIcon, ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";

const AssignButton = ({ selectedAssessivs, setSelectedAssessivs, assessiv, isPractice }) => {

  const isAdded = selectedAssessivs.find(selectedAssessiv => selectedAssessiv.assessivId === assessiv.assessivId && selectedAssessiv.assessivVersionId === assessiv.assessivVersionId);

  const handleOnClick = () => {
    if (isAdded) {
      setSelectedAssessivs(
        selectedAssessivs.filter(selectedAssessiv => !(selectedAssessiv.assessivId === assessiv.assessivId && selectedAssessiv.assessivVersionId === assessiv.assessivVersionId))
      );
    } else {
      setSelectedAssessivs([
        ...selectedAssessivs,
        {
          assessivId: assessiv.assessivId,
          assessivVersionId: assessiv.assessivVersionId,
          assessivName: assessiv.name,
          id: `${assessiv.assessivId}${assessiv.assessivVersionId}`,
          value: assessiv.assessivId,
          additionalText: isPractice ? 'Practice' : 'Assessiv',
          type: isPractice ? 'Practice' : 'TestQuestion',
        }
      ]);
    }
  }

  return (
    <button
      type="button"
      onClick={handleOnClick}
      className={`mx-auto w-5 h-5 flex items-center justify-center rounded-full transition-all ${isAdded ? 'hover:bg-red-500/10 active:bg-red-500/20' : 'hover:bg-black/10 active:bg-black/20'}`}
    >
      {isAdded ? (
        <TrashIcon className="h-4 text-red-500"/>
      ) : (
        <PlusIcon className="h-4 text-black"/>
      )}
    </button>
  );
}

const AdaptiveSearchAssessivTable = ({ assessivs, isLoading, selectedAssessivs, setSelectedAssessivs, }) => {

  return (
    <div className="w-full overflow-y-auto max-h-[200px] rounded-xl scrollbar-thin border col-span-2 my-4 relative">
      <table className="w-full">
        <thead>
        <tr>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[60%] sticky top-0">Name</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[20%] text-center sticky top-0">Sections</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[20%] text-center sticky top-0">Assign</td>
        </tr>
        </thead>
        <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="3" className="p-3 text-base text-gray-400 text-center">
              Loading...
            </td>
          </tr>
        ) : !assessivs?.length ? (
          <tr>
            <td colSpan="3" className="p-3 text-base text-gray-400 text-center">
              No assessivs
            </td>
          </tr>
        ) : (
          assessivs.map((assessiv) => (
            <tr key={assessiv.assessivId}>
              <td className="px-3 py-1.5 text-xs text-black border-t">{assessiv.name}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">{assessiv.sectionCount}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                <AssignButton
                  assessiv={assessiv}
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
    </div>
  );
}

const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

const SORTING_FIELD = {
  NAME: 'name',
  DIFFICULTY: 'difficulty',
};

const PracticeSearchAssessivTable = ({
  assessivs,
  isLoading,
  selectedAssessivs,
  setSelectedAssessivs,
  difficultyNameById,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: SORT_DIRECTION.ASC
  });

  const sortedAssessivs = useMemo(() => {
    return  [...(assessivs || [])].sort((a, b) => {
      if (!sortConfig.key) return 0;

      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === SORTING_FIELD.DIFFICULTY) {
        aValue = parseInt(a.difficulty) || 0;
        bValue = parseInt(b.difficulty) || 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === SORT_DIRECTION.ASC ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === SORT_DIRECTION.ASC ? 1 : -1;
      }
      return 0;
    })
  }, [assessivs, sortConfig]);

  const requestSort = (key) => {
    let direction = SORT_DIRECTION.ASC;
    if (sortConfig.key === key && sortConfig.direction === SORT_DIRECTION.ASC) {
      direction = SORT_DIRECTION.DESC;
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowsUpDownIcon className="inline h-4 w-4 text-slate-400" />;
    }
    return sortConfig.direction === SORT_DIRECTION.ASC
      ? <ArrowUpIcon className="inline h-3 w-3 text-slate-600" />
      : <ArrowDownIcon className="inline h-3 w-3 text-slate-600" />;
  };

  return (
    <div className="w-full overflow-y-auto max-h-[200px] rounded-xl scrollbar-thin border col-span-2 my-4 relative">
      <table className="w-full">
        <thead>
        <tr>
          <td
            className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[35%] sticky top-0 cursor-pointer select-none hover:bg-slate-300 transition-all duration-150"
            onClick={() => requestSort(SORTING_FIELD.NAME)}
          >
            Name {getSortIcon(SORTING_FIELD.NAME)}
          </td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[30%] sticky top-0">
            Topic
          </td>
          <td
            className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[15%] text-center sticky top-0 cursor-pointer select-none hover:bg-slate-300 transition-all duration-150"
            onClick={() => requestSort(SORTING_FIELD.DIFFICULTY)}
          >
            Difficulty {getSortIcon(SORTING_FIELD.DIFFICULTY)}
          </td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[10%] text-center sticky top-0">
            Questions
          </td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[10%] text-center sticky top-0">
            Assign
          </td>
        </tr>
        </thead>
        <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="5" className="p-3 text-base text-gray-400 text-center">
              Loading...
            </td>
          </tr>
        ) : !sortedAssessivs?.length ? (
          <tr>
            <td colSpan="5" className="p-3 text-base text-gray-400 text-center">
              No assessivs
            </td>
          </tr>
        ) : (
          sortedAssessivs.map((assessiv) => (
            <tr key={assessiv.assessivId}>
              <td className="px-3 py-1.5 text-xs text-black border-t">
                {assessiv.name}
              </td>
              <td className="px-3 py-1.5 text-xs text-black border-t">
                {assessiv.topic}
              </td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                {difficultyNameById[assessiv.difficulty ?? ''] ?? assessiv.difficulty}
              </td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                {assessiv.questionCount}
              </td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                <AssignButton
                  isPractice
                  assessiv={assessiv}
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
    </div>
  );
}

export const SearchAssessivTable = ({
  isPractice,
  isLoading,
  selectedAssessivs,
  setSelectedAssessivs,
  regularAssessivs,
  practiceAssessivs,
  difficultyNameById,
}) => {

  if (isPractice) {
    return (
      <PracticeSearchAssessivTable
        difficultyNameById={difficultyNameById}
        isLoading={isLoading}
        selectedAssessivs={selectedAssessivs}
        setSelectedAssessivs={setSelectedAssessivs}
        assessivs={practiceAssessivs}
      />
    );
  }

  return (
    <AdaptiveSearchAssessivTable
      isLoading={isLoading}
      selectedAssessivs={selectedAssessivs}
      setSelectedAssessivs={setSelectedAssessivs}
      assessivs={regularAssessivs}
    />
  );
}