import {useMemo, useState} from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { ReactComponent as CloseCrossIcon } from "../../../../../../../../../assets/close-cross.svg";
import { ReactComponent as Check } from '../../../../../../../../../assets/Check.svg';
import { fetchInvitedAssessments } from "../../../../../../../../inviteUsers/components/AssessivsSelect";
import { DatePicker } from "../../../../../../../../../components/DatePicker";
import { SubmitLoader } from "../../../../../../../../../components/SubmitLoader";
import { assignAssessivsToGroup } from "../../../../../../../../../actions/assessment";
import { sendEmailAboutAssessivInvitationToMultipleStudents } from "../../../../../../../../../actions/emails";
import { fetchUsers } from "../../../../../../../../../actions/user";
import { groupsStore, loginStore } from "../../../../../../../../../store";
import { ExtendedSearchSection } from "../../../../../../../../inviteUsers/components/ExtendedSearchSection";

const parseAssessivsToOptions = (assessivs) => {
  return assessivs.map((assessiv) => ({
    ...assessiv,
    name: assessiv.assessivName,
    id: assessiv.assessivId + assessiv.assessivVersionId,
    value: assessiv?.assessivId
  }));
}

export const AssignAssessivToGroup = ({ students, groupName, groupId, handleCloseModal }) => {
  const { fullName } = loginStore();
  const { setStudents } = groupsStore();
  const [selectedAssessivs, setSelectedAssessivs] = useState([]);
  const [dueDate, setDueDate] = useState('');
  const [showDueDate, setShowDueDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUnselectAssessiv = (assessivId) => {
    setSelectedAssessivs(
      selectedAssessivs.filter((selectedAssessiv) => `${selectedAssessiv.assessivId}${selectedAssessiv.assessivVersionId}` !== assessivId)
    );
  }

  const selectedOptions = useMemo(
    () => parseAssessivsToOptions(selectedAssessivs),
    [selectedAssessivs]
  );

  const validateOptions = () => {
    if (!selectedAssessivs?.length) {
      toast.error('Select the assessivs to assign');
      return false;
    }

    if (!!selectedAssessivs?.length && showDueDate && !dueDate.endDate?.length) {
      toast.error('Select the "Expected by" date');
      return false;
    }

    return true;
  }

  const handleSubmitAssignment = (event) => {
    event.preventDefault();

    if (!groupId) {
      console.error('No groupId.');
      return;
    }

    if (!students?.length) {
      toast.error('No students in the group.');
      return;
    }

    if (validateOptions()) {
      setIsLoading(true);

      const assessivs = selectedAssessivs.map((selectedAssessiv) => ({
        assessivId: selectedAssessiv.assessivId,
        assessivVersionId: selectedAssessiv.assessivVersionId,
        dueDate: (showDueDate && dueDate?.endDate?.length) ? new Date(dueDate.endDate)?.toISOString() : undefined
      }));

      const studentsOnlyWithEmails = students?.reduce?.((acc, student) => {
        return student?.email ? [...acc, { email: student.email }] : acc;
      }, []) ?? [];

      assignAssessivsToGroup(groupId, assessivs)
        .then((data) => {
          console.log(data)
          sendEmailAboutAssessivInvitationToMultipleStudents({
            students: studentsOnlyWithEmails,
            teacherName: fullName
          }).then(() => {
              toast.success('Users are successfully assigned.');
              fetchInvitedAssessments();
              const { studentsPage, studentsSorting } = groupsStore.getState();
              fetchUsers(10, studentsPage - 1, studentsSorting).then((data) => {
                setStudents(data?.data ?? []);
              });
              setSelectedAssessivs([]);
            })
            .finally(() => setIsLoading(false));
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message ?? error?.response?.data?.title);
        });
    }
  }

  return createPortal(
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {opacity: 1},
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
      >
        <motion.div
          variants={{
            visible: { y: 0 },
            hidden: { y: 50 },
          }}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[660px] max-h-full overflow-y-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="relative bg-white rounded shadow">
            <div className="flex flex-col items-stretch pt-10 pb-2 px-8 rounded-t">
              <h2 className="text-xl text-center font-medium text-navySmoke mb-2">
                Assign to {groupName}
              </h2>
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                data-modal-hide="staticModal"
                onClick={handleCloseModal}
              >
                <CloseCrossIcon className="w-3 h-3" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className="flex flex-col items-center flex-stretch px-8 pb-10"
              onSubmit={handleSubmitAssignment}
            >
              <div className="flex gap-4 flex-col items-center">
                <div
                  className="w-[400px] py-2 px-3 border border-gray-300 rounded-md flex flex-wrap items-center gap-2 max-h-[160px] flex-1 text-slate-600 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
                  {selectedOptions?.length ? selectedOptions.map(selectedOption => (
                    <span
                      key={selectedOption.id}
                      className="flex flex-row gap-1 items-center bg-slate-100 hover:bg-slate-200 border border-gray-200 text-sm px-2 py-1 rounded-lg text-black"
                      onClick={() => handleUnselectAssessiv(selectedOption.id)}
                    >
                        {selectedOption.name}
                      <XCircleIcon
                        className="h-4 w-4 flex-shrink-0 text-gray-400 hover:text-gray-600 hover:cursor-pointer"/>
                      </span>
                  )) : (
                    <span className="text-sm text-gray-400">No selected assessivs</span>
                  )}
                </div>

                <ExtendedSearchSection
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />

                <label
                  className="w-[400px] flex items-center gap-2 text-sm text-navySmoke relative cursor-pointer select-none -my-1">
                  <input
                    className="absolute w-0 h-0 opacity-0"
                    type="checkbox"
                    checked={showDueDate}
                    onChange={() => setShowDueDate(!showDueDate)}
                  />

                  <div
                    className={`flex items-center justify-center w-3.5 h-3.5 border rounded ${showDueDate ? 'bg-skyBlue border-skyBlue' : 'border-whisperBlue bg-transparent'}`}>
                    {showDueDate && <Check className="scale-90"/>}
                  </div>

                  Due Date
                </label>

                {showDueDate && (
                  <div className="w-[400px]">
                    <DatePicker
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                    />
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="mx-auto mt-5 inline-block col-span-2 bg-blue-600 hover:bg-blue-700 disabled:bg-gray-200 text-white font-bold py-2 px-4 rounded-lg w-[400px] disabled:text-gray-400 disabled:font-normal"
              >
                {isLoading
                  ? <SubmitLoader/>
                  : <span className="py-2 px-4 text-md">ASSIGN</span>}
              </button>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
}