import React, { useState, useEffect } from 'react';
import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/react/24/solid";
import { ReactComponent as Clock } from '../assets/assessmentPlatform/clock.svg';
import { assessmentStore, questionStore, timerStore } from "../store";
import {
  ACCOMMODATIONS_OPTION,
  extendTimeByAccommodations
} from "../pages/assessmentStart/components/ViewCustomisation";

export const Timer = ({ showEmpty, initialTime, onEnd }) => {
  const { accommodations } = assessmentStore();
  const { isTimerPaused, setIsTimerPaused } = questionStore();
  const isBreak = timerStore(state => state.isBreak);
  const [timeLeft, setTimeLeft] = useState(() => extendTimeByAccommodations(initialTime, accommodations));

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    if (timeLeft <= 0 || isTimerPaused || accommodations === ACCOMMODATIONS_OPTION.UNTIMED) {
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        const newTime = prevTime - 1;
        if (newTime === 0) {
          onEnd?.();
        }
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeLeft, onEnd, isTimerPaused]);

  const togglePlayPause = () => {
    setIsTimerPaused(!isTimerPaused);
  }

  if (accommodations === ACCOMMODATIONS_OPTION.UNTIMED) {
    return null;
  }

  return (
    <div className="flex items-center justify-end gap-2">
      <Clock />

      {(accommodations === ACCOMMODATIONS_OPTION.UNTIMED) && (
        <button disabled={isBreak} onClick={togglePlayPause}>
          {isTimerPaused ? <PlayCircleIcon className="h-[22px]"/> : <PauseCircleIcon className="h-[22px]"/>}
        </button>
      )}

      <div className="w-12 text-base font-medium text-white">{formatTime(showEmpty ? 0 : timeLeft)}</div>
    </div>
  );
}
