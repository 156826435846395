import { RadioGroup } from "@headlessui/react";

export const ASSIGN_TO = {
  SINGLE_USER: 'single-user',
  GROUP: 'group',
};

const items = [
  {
    value: ASSIGN_TO.SINGLE_USER,
    text: 'Assign to Individual student',
  },
  {
    value: ASSIGN_TO.GROUP,
    text: 'Assign to a Group of students',
  },
];

export const AssignToSection = ({ assignTo, setAssignTo }) => {

  return (
    <RadioGroup value={assignTo} onChange={setAssignTo} className="flex flex-col gap-2 w-full pb-2 border-b">
      {items.map(({ text, value}) => (
        <RadioGroup.Option value={value}>
          <label className="flex items-center gap-2 text-base text-black cursor-pointer">
            <input
              type="radio"
              checked={assignTo === value}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:ring-offset-gray-800 focus:ring-2"
            />
            {text}
          </label>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}