import { useCallback, useMemo } from "react";
import {  StatusLabel } from "../../general/StatusLabel";
import { assessmentStore, loginStore, tablesStore } from "../../../../../../../store";
import Pagination from "../../../../../../../components/Pagination";
import { formatDateStatus } from "../../../../../../../helpers/date.ts";
import { convertUserIDtoUserName } from "../../../../../../../helpers/string.ts";
import { Table } from "../../../../../../../components/Table";
import { ASSESSMENT_STATUS } from "../../../../../../../helpers/constants";

const ASSESSMENTS_PER_PAGE = 10;

const tableColumns = [
  { content: 'Name', id: 'Name', className: 'pl-0 py-[14px] font-normal' },
  { content: 'Assigned By', id: 'Assigned By', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'Status', id: 'Status', className: 'text-center lg:text-right pr-0 py-[14px] font-normal' },
];

export const AssessmentTable = ({ onClickAssessment, assessments }) => {
  const { id } = loginStore();
  const { setAssessmentId, setAssessmentVersionId, setEducatorId, resetResults, setResultsReady } = assessmentStore();
  const { assessmentTablePage, setAssessmentTablePage } = tablesStore();

  const filteredAssessments = useMemo(() => {
    const firstPageIndex  = ASSESSMENTS_PER_PAGE * (assessmentTablePage - 1);
    const lastPageIndex  = ASSESSMENTS_PER_PAGE * assessmentTablePage - 1;
    return assessments?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [assessments, assessmentTablePage]);

  const handleStartAssessment = useCallback((assessment) => {
    Promise.all([
      setAssessmentId(assessment?.assessmentId),
      setAssessmentVersionId(assessment?.assessmentVersionId),
      setEducatorId(assessment?.educatorId),
      resetResults(),
      setResultsReady(false)
    ])
      .then(onClickAssessment)
      .catch((error) => console.log(error));
  }, [onClickAssessment, resetResults, setAssessmentId, setAssessmentVersionId, setEducatorId, setResultsReady])

  const tableData = useMemo(() => {
    return filteredAssessments?.map(assessment => {
      const isSelfAssigned = assessment?.educatorId === id;
      const assignedBy = isSelfAssigned ? 'self-assigned' : convertUserIDtoUserName(assessment?.educatorId);

      return {
        id: assessment.assessmentId + (assessment?.dueDate ?? ''),
        onClick: () => handleStartAssessment(assessment),
        cells: [
          {
            className: 'pl-0 py-[14px]',
            id: assessment.assessmentId + 'Name',
            content: assessment.name
          },
          {
            id: assessment.assessmentId + 'Assigned By',
            className: isSelfAssigned ? 'underline' : '',
            content: assignedBy
          },
          {
            className: 'pr-0 font-normal',
            id: assessment.assessmentId + 'Status Score',
            content: assessment?.status === ASSESSMENT_STATUS.COMPLETED ? <StatusLabel status={assessment?.status} /> : formatDateStatus(assessment?.dueDate ?? '')
          },
        ]
      }
    }) ?? [];
  }, [id, filteredAssessments, handleStartAssessment]);

  return (
    <>
      <Table columns={tableColumns} rows={tableData} containerClassName="mb-2" columnRowClassName="text-xs" />

      <Pagination
        currentPage={assessmentTablePage}
        onPageChange={setAssessmentTablePage}
        totalPages={Math.ceil((assessments?.length ?? 0) / 10)}
      />
    </>
  );
}