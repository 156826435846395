import { InviteAssignStudent } from "../components/InviteSection";

export const AssignStudent = () => {
  return (
    <InviteAssignStudent
      title="Assign"
      confirmText="Assign"
      newUserConfirmText="Invite and Assign"
    />
  );
}