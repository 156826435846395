import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import { AddToGroupButton } from "./AddToGroup";
import { SendAssignment } from "./SendAssignment";
import {useClickOutside} from "../../../../../../../helpers/hooks/useClickOutside.ts";
import { ReactComponent as CrossIcon } from "../../../../../../../assets/add-cross.svg";

export const AddOptions = ({ userId, email, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setIsSubModalVisible] = useState(false);
  const buttonRef = useRef(null);
  const ref = useRef(null);

  const openModal = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
  }

  const getModalStyle = () => {
    if (!buttonRef.current) return { top: 0, left: 0 };

    const rect = buttonRef.current.getBoundingClientRect();
    return { top: rect.bottom + window.scrollY + 4, left: rect.left + window.scrollX - 114 };
  };

  useClickOutside(ref, () => {
    setIsSubModalVisible(prevSubModalVisible => {
      if (!prevSubModalVisible) {
        setIsModalOpen(false);
      }
      return prevSubModalVisible;
    })
  });

  return (
    <>
      <button
        ref={buttonRef}
        className={`flex items-center gap-1 p-1.5 rounded-full text-xs hover:bg-gray-200 active:bg-gray-300 ${isModalOpen ? '' : 'invisible group-hover:visible opacity-0 group-hover:opacity-100'}`}
        onClick={openModal}
      >
        <CrossIcon/>
      </button>

      {isModalOpen && (
        createPortal(
          <motion.div
            ref={ref}
            className="absolute z-[21] mt-1 ml-6 transform"
            style={getModalStyle()}
            transition={{duration: 0.5, stiffness: 150}}
            variants={{
              visible: {y: 0},
              hidden: {y: 50},
            }}
          >
            <div className="flex flex-col gap-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-2 bg-white">
              <AddToGroupButton
                isTextButton
                userId={userId}
                title="Assign to Group"
                onVisibilityChange={setIsSubModalVisible}
              />

              <SendAssignment
                isTextButton
                email={email}
                name={name}
                onVisibilityChange={setIsSubModalVisible}
              />
            </div>
          </motion.div>,
          document.body
        )
      )}
    </>
  );
}