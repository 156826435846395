import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { ExtendedSearchSection } from "./ExtendedSearchSection";
import { ASSIGN_TO, AssignToSection } from "./AssignToSection";
import { SingleTypeSelect } from "./SingleTypeSelect";
import { SubmitLoader } from "../../../components/SubmitLoader";
import { InputLoaderWrapper } from "../../../components/InputLoaderWrapper";
import { fetchInvitedAssessments } from "./AssessivsSelect";
import { DatePicker } from "../../../components/DatePicker";
import { ReactComponent as Check } from '../../../assets/Check.svg';
import { Header } from "../../../components/Header";
import {isStudent, ROLE} from "../../../helpers/authentication.ts";
import { MenuCard } from "../../dashboard/components/utilityComponents";
import {
  assignAssessivsToGroup,
  inviteStudentToAssessiv,
} from "../../../actions/assessment";
import {
  sendEmailAboutAssessivInvitation,
  sendEmailAboutAssessivInvitationToMultipleStudents
} from "../../../actions/emails";
import {
  inviteUser,
} from "../../../actions/user";
import {assessmentStore, groupsStore, loginStore } from "../../../store";
import { checkUserExists } from "../../../actions/registration";
import { createDebounceFunction } from "../../../helpers/functions.ts";
import { emailRegExp } from "../../../helpers/validation.ts";
import { generateRandomThreeDigitNumber } from "../../../helpers/number.ts";
import {fetchGroups} from "../../../actions/group";

const initialDatepickerObject = {
  startDate: '',
  endDate: ''
};

const debouncedUserExists = createDebounceFunction((userEmail, setUserExists, setFirstName, setLastName, setIsUserCheckLoading, setShowNameInputByDefault) => {
  setIsUserCheckLoading(true);
  checkUserExists(userEmail)
    .then((data) => {
      setUserExists(data?.data ?? true);
      const name = data?.data?.name;
      if (typeof name === 'string') {
        const [firstName, lastName] = name.split(' ');
        setFirstName(firstName);
        setLastName(lastName);
      }
    })
    .catch((error) => {
      const value = error?.response?.data?.errorCode;
      setUserExists(!(value === 'User doesn\'t exist'));
    })
    .finally(() => {
      setShowNameInputByDefault(true);
      setIsUserCheckLoading(false);
    });
}, 600);

const parseAssessivsToOptions = (assessivs) => {
  return assessivs.map((assessiv) => ({
    ...assessiv,
    name: assessiv.assessivName,
    id: assessiv.assessivId + assessiv.assessivVersionId,
    value: assessiv?.assessivId
  }));
}

export const InviteAssignStudent = ({
  title = 'Invite student',
  confirmText = 'Invite',
  newUserConfirmText = 'Invite and Assign',
}) => {
  const { fullName } = loginStore();
  const { studentsResults } = assessmentStore();
  const { groups, setGroups } = groupsStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [isUserCheckLoading, setIsUserCheckLoading] = useState(false);
  const [showNameInputByDefault, setShowNameInputByDefault] = useState(false);
  const [selectedAssessivs, setSelectedAssessivs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDueDate, setShowDueDate] = useState(false);
  const [dueDate, setDueDate] = useState(initialDatepickerObject);
  const [assignTo, setAssignTo] = useState(ASSIGN_TO.SINGLE_USER);
  const [groupId, setGroupId] = useState('');

  const groupOptions = useMemo(() => {
    return groups?.map?.((item) => ({
      name: item.name,
      value: item.id,
      id: item.id,
    })) ?? [];
  }, [groups]);

  const handleOnChangeEmail = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (emailRegExp.test(newEmail)) {
      debouncedUserExists(newEmail, setUserExists, setFirstName, setLastName, setIsUserCheckLoading, setShowNameInputByDefault);
    } else {
      setUserExists(false);
    }
  }

  const handleUnselectAssessiv = (assessivId) => {
    setSelectedAssessivs(
      selectedAssessivs.filter((selectedAssessiv) => `${selectedAssessiv.assessivId}${selectedAssessiv.assessivVersionId}` !== assessivId)
    );
  }

  const invitedAssessivsId = useMemo(() => {
    return studentsResults
      .filter((invitedAssessiv) => invitedAssessiv.studentEmail === email)
      .map((invitedAssessiv) => invitedAssessiv.assessivId);
  }, [studentsResults, email]);

  const selectedOptions = useMemo(
    () => {
      const currentSelectedAssessivs = assignTo === ASSIGN_TO.GROUP ? selectedAssessivs : selectedAssessivs.filter(
        (assessiv) => !invitedAssessivsId.includes(assessiv.assessivId));

      return parseAssessivsToOptions(currentSelectedAssessivs);
    },
    [selectedAssessivs, invitedAssessivsId, assignTo]
  );

  const validateOptions = () => {
    if (!emailRegExp.test(email)) {
      toast.error('Invalid email.');
      return false;
    }

    if (!userExists && (!firstName.length && !lastName.length)) {
      toast.error('Name\'s inputs are empty.');
      return false;
    }

    if (!!selectedAssessivs?.length && showDueDate && !dueDate.endDate?.length) {
      toast.error('Select the "Expected by" date');
      return false;
    }

    return true;
  }

  const validateGroupOptions = () => {
    if (!selectedAssessivs?.length) {
      toast.error('Select the assessivs to assign');
      return false;
    }

    if (!!selectedAssessivs?.length && showDueDate && !dueDate.endDate?.length) {
      toast.error('Select the "Expected by" date');
      return false;
    }

    return true;
  }

  const resetForm = () => {
    setSelectedAssessivs([]);
    setEmail('');
    setFirstName('');
    setLastName('');
    setGroupId('');
    setUserExists(false);
  }

  const sendEmail = (password) => {
    sendEmailAboutAssessivInvitation({
      email,
      password,
      teacherName: fullName
    })
      .then(() => {
        toast.success('User is successfully invited.');
        fetchInvitedAssessments();
        resetForm();
      })
      .finally(() => setIsLoading(false));
  }

  const handleSubmitInvitation = (event) => {
    event.preventDefault();

    if (!selectedAssessivs?.length && !!emailRegExp.test(email) && !!firstName?.length && !!lastName?.length) {
      if (!emailRegExp.test(email)) {
        toast.error('Invalid email.');
        return;
      }

      setIsLoading(true);
      const password = `Assessiv@${generateRandomThreeDigitNumber()}`;
      inviteUser(email, firstName, lastName, password)
        .then(() => {
          if (!userExists) {
            sendEmail(password);
          } else {
            toast.success('User is successfully invited.');
            resetForm();
          }
        })
        .catch(() => {
          toast.error('Error when inviting user.');
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    if (!isUserCheckLoading && validateOptions()) {
      setIsLoading(true);

      const assessivs = selectedAssessivs.map((selectedAssessiv) => ({
        assessivId: selectedAssessiv.assessivId,
        assessivVersionId: selectedAssessiv.assessivVersionId,
      }));

      inviteStudentToAssessiv(email, `${firstName} ${lastName}`, assessivs, (showDueDate && dueDate?.endDate?.length) ? new Date(dueDate.endDate)?.toISOString() : undefined)
        .then((data) => {
          const password = data?.data?.find(result => result.userCreated)?.password;
          sendEmail(password);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message ?? error?.response?.data?.title);
        });
    }
  }

  const handleSubmitAssignmentToGroup = (event) => {
    event.preventDefault();

    if (!groupId) {
      toast.error('No groupId.');
      return;
    }

    const students = groups
      ?.find?.(group => group?.id === groupId)?.users
      ?.filter?.(student => student?.role === ROLE.STUDENT) ?? [];

    if (!students?.length) {
      toast.error('No students in the group.');
      return;
    }

    if (validateGroupOptions()) {
      setIsLoading(true);

      const assessivs = selectedAssessivs.map((selectedAssessiv) => ({
        assessivId: selectedAssessiv.assessivId,
        assessivVersionId: selectedAssessiv.assessivVersionId,
        dueDate: (showDueDate && dueDate?.endDate?.length) ? new Date(dueDate.endDate)?.toISOString() : undefined
      }));

      const studentsOnlyWithEmails = students?.reduce?.((acc, student) => {
        return student?.email ? [...acc, { email: student.email }] : acc;
      }, []) ?? [];

      assignAssessivsToGroup(groupId, assessivs)
        .then(() => {
          sendEmailAboutAssessivInvitationToMultipleStudents({
            students: studentsOnlyWithEmails,
            teacherName: fullName
          }).then(() => {
            toast.success('Group\'s users are successfully assigned.');
            resetForm();
            fetchInvitedAssessments();
          })
            .finally(() => setIsLoading(false));
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message ?? error?.response?.data?.title);
        });
    }
  }

  useEffect(() => {
    if (isStudent()) {
      navigate(-1);
    }

    fetchGroups()
      .then((data) => {
        setGroups(data?.data ?? []);
      })
      .catch(console.log);
  }, []);

  return (
    <div className="App-header flex flex-col bg-slate-200">
      <Header />
      <div className="flex flex-1 bg-slate-200 z-0">
        <MenuCard
          className="flex items-center justify-center w-full"
          animInContainerClassName="overflow-y-auto p-10 flex-1 flex items-center justify-center w-full"
        >
          <form
            className="flex flex-col items-center max-w-full h-full w-full"
            onSubmit={assignTo === ASSIGN_TO.SINGLE_USER ? handleSubmitInvitation : handleSubmitAssignmentToGroup}
          >
            <div className="flex gap-4 flex-col items-center">
              <h1 className="text-black font-semibold text-center text-2xl mb-4">{title}</h1>

              <AssignToSection
                assignTo={assignTo}
                setAssignTo={setAssignTo}
              />

              {assignTo === ASSIGN_TO.SINGLE_USER ? (
                <InputLoaderWrapper className="w-[400px]" isLoaderVisible={isUserCheckLoading}>
                  <input
                    required
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleOnChangeEmail}
                    placeholder="Student's email"
                    className="block w-full text-black rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 pl-4 pr-12"
                  />
                </InputLoaderWrapper>
              ) : (
                <SingleTypeSelect
                  selectedOptionText={groupOptions.find(item => item.value === groupId)?.name ?? ''}
                  onSelectOption={(option) => setGroupId(option?.value)}
                  options={groupOptions}
                  placeholder="Select Group"
                />
              )}

              {showNameInputByDefault && (
                <div className="flex items-center gap-4 w-[400px]">
                  <input
                    required
                    name="firstName"
                    type="text"
                    disabled={!!userExists}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder="First name"
                    className="block w-full text-black rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4 disabled:bg-gray-200 disabled:text-gray-400 transition"
                  />
                  <input
                    required
                    name="lastName"
                    type="text"
                    disabled={!!userExists}
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    placeholder="Last name"
                    className="block w-full text-black rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4 disabled:bg-gray-200 disabled:text-gray-400 transition"
                  />
                </div>
              )}

              <div
                className="w-[400px] py-2 px-3 border border-gray-300 rounded-md flex flex-wrap items-center gap-2 max-h-[160px] flex-1 text-slate-600 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
                {selectedOptions?.length ? selectedOptions.map(selectedOption => (
                  <span
                    key={selectedOption.id}
                    className="flex flex-row gap-1 items-center bg-slate-100 hover:bg-slate-200 border border-gray-200 text-sm px-2 py-1 rounded-lg text-black"
                    onClick={() => handleUnselectAssessiv(selectedOption.id)}
                  >
                        {selectedOption.name}
                    <XCircleIcon
                      className="h-4 w-4 flex-shrink-0 text-gray-400 hover:text-gray-600 hover:cursor-pointer"/>
                      </span>
                )) : (
                  <span className="text-sm text-gray-400">No selected assessivs</span>
                )}
              </div>

              <ExtendedSearchSection
                selectedAssessivs={selectedAssessivs}
                setSelectedAssessivs={setSelectedAssessivs}
              />

              <label
                className="w-[400px] flex items-center gap-2 text-sm text-navySmoke relative cursor-pointer select-none -my-1">
                <input
                  className="absolute w-0 h-0 opacity-0"
                  type="checkbox"
                  checked={showDueDate}
                  onChange={() => setShowDueDate(!showDueDate)}
                />

                <div
                  className={`flex items-center justify-center w-3.5 h-3.5 border rounded ${showDueDate ? 'bg-skyBlue border-skyBlue' : 'border-whisperBlue bg-transparent'}`}>
                  {showDueDate && <Check className="scale-90"/>}
                </div>

                Due Date
              </label>

              {showDueDate && (
                <div className="w-[400px]">
                  <DatePicker
                    dueDate={dueDate}
                    setDueDate={setDueDate}
                  />
                </div>
              )}
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="mx-auto mt-5 inline-block col-span-2 bg-blue-600 hover:bg-blue-700 disabled:bg-gray-200 text-white font-bold py-2 px-4 rounded-lg w-[400px] disabled:text-gray-400 disabled:font-normal"
            >
              {isLoading
                ? <SubmitLoader/>
                : <span className="py-2 px-4 text-md">{userExists ? confirmText : newUserConfirmText}</span>}
            </button>
          </form>
        </MenuCard>
      </div>
    </div>
  );
}